import styled from 'styled-components'

export const MainContainer = styled.div`
  padding: 20px;
  width: 85vw;
`;

export const Header = styled.div`
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 7px;
  display:flex;
  justify-content : space-between;
  font-size: large;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Icon = styled.a`
  cursor: pointer;
  color: grey;
  font-size: large;

  &:hover {
    color: #02475c;
    font-weight: bold; 
    transition: transform 0.3s ease;
  }
`;