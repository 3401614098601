import React, {useState, useEffect} from "react";
import {Routes, Route, useNavigate,useLocation,Link} from 'react-router-dom';
import moment from 'moment';
import {  Table, Select, DatePicker, Spin, Input } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import * as Styled from './style.js';
import { postData,getData } from '../../config/api-connect';
import Spinner from '../../components/spinner'

const { Option } = Select;
const { TextArea } = Input;

const Batches = () => {

  var local_city = JSON.parse(localStorage.getItem('cityId'))
  var local_selectedVenueId = JSON.parse(localStorage.getItem('selectedVenueId'))
  if(!local_selectedVenueId){
    local_selectedVenueId = 'All'
  }

  var local_game_type = JSON.parse(localStorage.getItem('gameType'))
  if(!local_game_type){
    local_game_type = 8
  }

  const [loading, setLoading] = useState(true)
  const [venues, setVenues] = useState([])
  const [cities, setCities] = useState([])
  const [cityId, setCityId] = useState(local_city)
  const [gameType, setGameType] = useState(local_game_type)
  const [gameLevel, setGameLevel] = useState('Beginner')
  const [venuelist, setVenuelist] = useState([])
  const [selectedVenueId, setSelectedVenueId] = useState(local_selectedVenueId)
  const [batchesData, setBatchesData] = useState([])

  useEffect(() => {
    localStorage.setItem('selectedVenueId',JSON.stringify(selectedVenueId))
    localStorage.setItem('cityId',JSON.stringify(cityId))
    localStorage.setItem('gameType',JSON.stringify(gameType))
  }, [selectedVenueId,cityId,gameType]);

  useEffect(() => {
     getVenues()
  }, []); 

  useEffect(() => {
    if(cityId !== ''){
      setLoading(true)
      getBatchDetails()
    }
  }, [selectedVenueId,cityId,gameType,gameLevel]); 

  useEffect(() => {
    getVenuesInCity()
  }, [cities,cityId]);

  const getVenuesInCity =() =>{
    if(cities[0] && !cityId){
      setCityId(cities[0].id)
    }
    if(cityId == 'All'){
      setVenuelist(venues)
    }
    else{
      setVenuelist( 
        venues.filter((item)=>{
              return item.city === cityId
            })
      ) 
    }
    setSelectedVenueId('All')
  }

  const getVenues = async () => {
    await getData('/business/get_venues_for_buddies/')
    .then(response =>{
      setVenues(response.result)
      setCities(response.cities)
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  const getBatchDetails = () => {
    getData(`/business/get_batches_summary/?venue_id=${selectedVenueId}&city_id=${cityId}&game_type_id=${gameType}&game_level=${gameLevel}`)
    .then(response =>{
      setBatchesData(response.result)
      setLoading(false)
    })
    .catch(error => {
      setLoading(false)
      alert(error.message);
    });
  }


  const columns = batchesData.length > 0 ? 
    [
      ...Object.keys(batchesData[0]).filter(key => key !== 'venue_id').map(key => ({
        title: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),  
        dataIndex: key,
        key: key,
        align: key !== 'venue' && 'center',
        sorter: key !== 'venue' ? (a, b) => a[key] - b[key] : false,
        render: key === 'venue' ? 
                  (text, record) => {
                    // const cleanedText = text.replace(/Sporthood/i, "").trim();
                    const venuename = text.length > 30 ? `${text.slice(0, 30)}...` : text;
                    return(
                    <Link to={`/batchDetails/${record.venue_id}/${gameLevel}`} className='venueTitle'>
                      {venuename}
                    </Link>
                    )
                  }
                : 
                key == 'utilization' ?
                  (text, record) => {
                    const value = record.utilization
                    return(
                      <span style={{padding:'5px',borderRadius:'5px',fontWeight:300,backgroundColor: value >= 80 ? '#228B22' : (value < 80 && value >= 50) ? '#DAA520' : (value < 50 && value >= 30) ? '#FF8C00' : '#FF4D4D',color:'white'}} >
                        {value + '%'}                
                      </span>
                    )
                  }
                :
                key == '<30%' || key == '30-50%' || key == '50-80%' || key == '>80%' ?
                text => 
                  <span style={{padding:'5px',borderRadius:'5px',color:key == '<30%' ? '#FF4D4D': key == '30-50%' ? '#FF8C00' : key == '50-80%' ? '#DAA520' : '#228B22' ,fontWeight:600}}>{text}</span>
                :

                text => text
        })),
      {
        title: '',
        dataIndex: 'venue_id',
        render: (value) => {
          return(
            <Link to={`/batchDetails/${value}/${gameLevel}`} className='venueTitle'>
              <ArrowRightOutlined/>
            </Link>
            )
        }
      }
    ]
  : 
    [];

  return (
    <Spin spinning={loading} indicator={<Spinner />}>
      <Styled.MainContainer>

        <Styled.TopDiv>    
          <Styled.SelectorContainer>

            {cities.length > 1 &&
              <Styled.Selector>
                <span className='indicatorText'>City</span>
                <Select
                  className = 'mainSelector'
                  style={{width: 150}}
                  showSearch = {true}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                  onChange={setCityId}
                  value = {cityId}
                  bordered={false}
                >
                  <Option value="All" name="All"> All </Option>
                  {
                    cities.map(item=>(
                      <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                    ))
                  }
                </Select>
              </Styled.Selector>
            }

            <Styled.Selector> 
              <span className='indicatorText'>Venue</span>
              <Select
                className = 'mainSelector'
                style={{width: 300}}
                showSearch = {true}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => option.name.toLowerCase().includes(input.toLowerCase())}
                onChange={setSelectedVenueId}
                value = {selectedVenueId}
                bordered={false}
              >
                <Option value="All" name="All"> All </Option>
                {venuelist.map(item=>(
                  <Option value={item.id} key={item.id} name = {item.name}> {item.name} </Option>    
                  ))
                }
              </Select>
            </Styled.Selector>

            <Styled.Selector>
              <span className='indicatorText'>Program</span>
              <Select
                  className = 'mainSelector'
                  style={{width: 210}}
                  onChange={setGameType}
                  value = {gameType}
                  bordered={false}
                >
                  <Option value="8"> Football Academy </Option>
                  <Option value="10"> Badminton Academy </Option>
                  <Option value="65"> Swimming Academy </Option>

                </Select>
            </Styled.Selector>

            <Styled.Selector>
              <span className='indicatorText'>Skill Level</span>
              <Select
                  className = 'mainSelector'
                  style={{width: 210}}
                  onChange={setGameLevel}
                  value = {gameLevel}
                  bordered={false}
                >
                  <Option value="Beginner"> Beginner </Option>
                  <Option value="Advanced"> Advanced </Option>
                  <Option value="Elite"> Elite </Option>
                </Select>
            </Styled.Selector>

          </Styled.SelectorContainer>
        </Styled.TopDiv>

        <Styled.Header>          
          <span> Batch Overview</span>
        </Styled.Header>

        <Table
          className="ant-table-thead"
          rowKey="id"
          columns={columns}
          dataSource={batchesData}
          pagination={{ pageSize: 20, showSizeChanger: false}}
         />

      </Styled.MainContainer>
    </Spin>
  );
}

export default Batches;